import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import './NuestraEmpresa.css';

const directors = [
  {
    name: 'Juan Jara Parada',
    position: 'CEO Gestión y Marketing',
    description: 'Responsable de liderar la gestión y estrategias de marketing de la empresa.',
    image: './img/juan jara perfil.webp',
  },
  {
    name: 'Juan Pozo Aravena',
    position: 'CTO Gestión y Tecnología',
    description: 'Encargado de dirigir las operaciones tecnológicas y la gestión de la tecnología.',
    image: './img/conferencia 2.webp',
  },
];

const NuestraEmpresa = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const titleRef = useRef(null);
  const imageRef = useRef(null);
  const columnLeftRef = useRef(null);
  const columnRightRef = useRef(null);
  const visionRef = useRef(null);
  const missionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const elements = [
        columnLeftRef.current,
        columnRightRef.current,
        titleRef.current,
        imageRef.current,
        visionRef.current,
        // missionRef.current, // Comentamos la misión
      ];

      elements.forEach((element) => {
        if (element) { // Verificar si el elemento existe
          const rect = element.getBoundingClientRect();
          if (rect.top < window.innerHeight && rect.bottom >= 0) {
            element.classList.add('animate-us');
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    // Verificar inicialmente si los elementos ya están en vista
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 2 : directors.length - 2));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex >= directors.length - 2) {
        return 0; // Reinicia el carrusel cuando se alcanza la última card
      }
      return prevIndex + 2;
    });
  };

  return (
    <div className="all-us">
      <Navbar />

      <section className="nuestra-empresa-us">
        <div className="columna-izquierda-us" ref={columnLeftRef}>
          <h1 className="titulo-grande-us" ref={titleRef}>Nuestra Empresa</h1>
          <p className="descripcion-us">
            Somos un equipo multidisciplinario que ofrece servicios de asesoría en <strong>Marketing
              Digital</strong> y venta de <strong>Productos Digitales</strong>.
            Te brindamos un Acompañamiento en todos los
            pasos de tu negocio.
          </p>

          <div className="carrusel-container-us" ref={imageRef}>
            <button className="carrusel-btn-us prev-us" onClick={handlePrevClick}>
              &#10094;
            </button>
            <div className="directores-carrusel-us">
              <div
                className="directores-cards-us"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }} // Usando currentIndex desde el estado
              >
                {directors.map((director, index) => (
                  <div key={index} className="director-card-us">
                    <div className="director-icon-us">
                      <img src={director.image} alt={director.name} />
                    </div>
                    <h3>{director.name}</h3>
                    <h4>{director.position}</h4>
                  </div>
                ))}
              </div>
            </div>
            <button className="carrusel-btn-us next-us" onClick={handleNextClick}>
              &#10095;
            </button>
          </div>

        </div>

      </section>

      <Footer />
    </div>
  );
};

export default NuestraEmpresa;
