import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './Home/Home';
import NuestraEmpresa from './NuestraEmpresa/NuestraEmpresa';
import Asesorias from './Servicios/Asesorias';
import Contacto from './Contacto/Contacto';
import Products from './Productos/Products';
import Mas_Planes from './Home/Mas_Planes';

function App() {
  return (
    <Router>
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/quienes-somos" element={<NuestraEmpresa />} />
          <Route path="/servicios" element={<Asesorias />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/productos" element={<Products />} />
          <Route path="/Mas_Planes" element={<Mas_Planes />} /> 
        </Routes>
      </div>
    </Router>
  );
}

export default App;
