import React, { useState } from "react";
import "./Contacto.css";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import emailjs from "emailjs-com";

const Contacto = () => {
  const [message, setMessage] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [name, setName] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [sent, setSent] = useState(false);

  // Función para manejar los cambios en las casillas
  const handleCheckboxChange = (option) => {
    setSelectedOptions((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option) // Eliminar si ya está seleccionado
        : [...prev, option] // Agregar si no está seleccionado
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const subtitle = selectedOptions.length
      ? `Categorías seleccionadas: ${selectedOptions.join(", ")}`
      : "";

    const templateParams = {
      from_name: name,
      to_name: "Soluciones Digitales Chile",
      message: `${subtitle}\n\n${message}\n\nNúmero de WhatsApp: ${whatsapp}`,
      reply_to: whatsapp,
    };

    emailjs
      .send("service_hjtczth", "template_hqqkut8", templateParams, "OtVefZRXKM3KBu2ye")
      .then(
        (response) => {
          console.log("Mensaje enviado con éxito!", response.status, response.text);
          setSent(true);

          // Limpiar los campos del formulario
          setName("");
          setMessage("");
          setWhatsapp("");
          setSelectedOptions([]);
        },
        (err) => {
          console.error("Error al enviar el mensaje", err);
        }
      );
  };

  return (
    <div>
      <Navbar />
      <section className="contactanos-section-contact">
        <div className="container-contact">
          <h1 className="contactanos-title-contact">Contáctanos</h1>
          <div className="form-card-contact">
            <div className="form-content-contact">
              <div className="form-left-contact">
                <h2>Escríbenos Tus Dudas</h2>
                <div className="checkbox-group-contact">
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedOptions.includes("Asesorías")}
                      onChange={() => handleCheckboxChange("Asesorías")}
                    />{" "}
                    Asesorías
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedOptions.includes("Sitio Web")}
                      onChange={() => handleCheckboxChange("Sitio Web")}
                    />{" "}
                    Sitio Web
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedOptions.includes("Redes Sociales")}
                      onChange={() => handleCheckboxChange("Redes Sociales")}
                    />{" "}
                    Redes Sociales
                  </label>
                </div>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    placeholder="Tu nombre y apellido"
                    className="form-input-contact"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <textarea
                    placeholder="Mensaje..."
                    className="form-textarea-contact"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                  <div className="whatsapp-container-contact">
                    <input
                      type="tel"
                      placeholder="WhatsApp/Contacto..."
                      className="whatsapp-input-contact"
                      value={whatsapp}
                      onChange={(e) => setWhatsapp(e.target.value)}
                      required
                    />
                    <button type="submit" className="submit-button-contact">
                      Enviar
                    </button>
                  </div>
                </form>
                {sent && <p className="sent-message">¡Mensaje enviado con éxito!</p>}
              </div>
              <div className="form-right-contact">
                <img
                  src="/img/contact-ceos.webp" // Cambia por tu imagen
                  alt="Equipo"
                  className="form-image-contact"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contacto;
