import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import './Mas_Planes.css';

const Mas_Planes = () => {

    return (
        <div className='mas-planes'>
            <Navbar />
            <div className='contenido-mas-planes'>
                <h1 className='titulo-mas-planes'>Más Planes</h1>
                <p className='subtitulo-mas-planes'>¡Próximamente!</p>
            </div>
            <Footer />
        </div>
    );
};

export default Mas_Planes;
