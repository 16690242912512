import { getStorage } from "firebase/storage";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCEry30bqFVWc0GGOnASu3JLuYl0RFf844",
    authDomain: "digital-solutions-spa.firebaseapp.com",
    databaseURL: "https://digital-solutions-spa-default-rtdb.firebaseio.com",
    projectId: "digital-solutions-spa",
    storageBucket: "digital-solutions-spa.firebasestorage.app",
    messagingSenderId: "329636372219",
    appId: "1:329636372219:web:5869f3d453e0b37cfd9a5e",
    measurementId: "G-P79PY9H10V"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };
